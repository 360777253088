import 'select2';

export default function select2Handler(selects) {
    const instances = [];

    function formatState(state) {
        if (!state.id) {
            return state.text;
        }

        const { element } = state;
        let outputText = '';
        const subText = element.getAttribute('data-subtext');
        const ogText = element.getAttribute('data-og-text');
        var $state = $(
            `<span class="${subText?.length > 0 ? 'subtext-class' : ''}">${
                subText?.length > 0 ? `<strong>${subText}</strong>` : ''
            } ${ogText}</span>`
        );

        return $state;
    }

    function formatResult(data) {
        console.log(data);
        return data;
    }

    selects.each(function () {
        let instance = null;
        const select = $(this);
        let settingsAttr = select.attr('data-settings');
        const selectOptions = {
            minimumResultsForSearch: -1,
        };

        if (settingsAttr) {
            settingsAttr = JSON.parse(settingsAttr);
            const { first_disabled, placeholder, is_anchors, is_offer_anchor } =
                settingsAttr;

            if (first_disabled) selectOptions.placeholder = placeholder;
            if (is_offer_anchor) selectOptions.templateSelection = formatState;
            if (is_offer_anchor) selectOptions.templateResult = formatState;
            instance = select.select2(selectOptions);
            instances.push(instance);

            if (is_anchors && !is_offer_anchor) {
                select.on('select2:select', function (e) {
                    select.addClass('selected');
                    const newHash = this.options[this.selectedIndex].value;
                    if (newHash !== 'disabled') {
                        window.location.hash = newHash;
                    }
                });

                $(document).ready(function () {
                    const hash = window.location.hash;

                    if (hash) {
                        const formattedHash = hash.replace('#', '');
                        select.val(formattedHash).trigger('change');
                    }
                });
            } else if (is_anchors && is_offer_anchor) {
                select.on('select2:select', function (e) {
                    select.addClass('selected');
                    const choosenPage = this.options[this.selectedIndex].value;
                    const currentPage = window.location.href;
                    if (choosenPage !== currentPage) {
                        window.location = choosenPage;
                    }
                });
            }
        } else {
            select.select2(selectOptions);
            select.on('select2:select', function (e) {
                select.addClass('selected');
            });
        }
    });

    return instances;
}
