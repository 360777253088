import debounce from 'lodash.debounce';
import BaseFilter from '../filters/baseFilter';
import Pagination from '../pagination/pagination';

export default class pressListSection {
    $ = jQuery;

    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.wrapper = this.root.querySelector('[data-press-list-wrapper]');
        this.loader = this.root.querySelector('[data-press-list-loader]');
        this.pagination = this.root.querySelector('[data-pagination]');
        this.categories = this.root.querySelectorAll(
            '[data-press-list-category]'
        );
        this.filter = this.root.querySelector('[data-filter-switcher]');
        this.filterSwitch = null;

        this.news = [];
        this.settings = JSON.parse(
            this.root.getAttribute('data-press-list-settings')
        );
        this.postType = this.settings.post_type;
        this.perPage = this.settings.per_page;
        this.is_show_date = this.settings.is_show_date;
        this.isFirstHighlighted = this.settings.is_first_highlighted;

        this.scrolledToItem = false;
        this.currentCategory = null;
        this.currentPage = 1;
        this.maxPages = 1;

        this.loadNews = debounce(this.getNews, 1000);

        if (this.settings.init_category)
            this.currentCategory = this.settings.init_category;

        return true;
    }

    init() {
        this.initFilter();
        this.initPagination();
        this.initCategories();

        if (this.isFirstHighlighted) this.refreshList();
        if (!this.isFirstHighlighted) this.scrollToItem();
    }

    scrollToItem() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const postId = urlParams.get('post_id');

        if (postId && !this.scrolledToItem) {
            const listItem = this.root.querySelector(
                `[data-press-list-item="${postId}"]`
            );
            if (listItem) {
                listItem.scrollIntoView({ behavior: 'smooth' });
                this.scrolledToItem = true;
            }
        }
    }

    initFilter() {
        if (!this.filter) return;
        new BaseFilter(this.filter, this.root);

        this.filterSwitch = this.onFilterSwitch.bind(this);
        this.root.addEventListener('filter-switcher-change', event =>
            this.filterSwitch(event)
        );
    }

    getNews() {
        const formData = new FormData();
        formData.append('action', 'news_list');
        formData.append('post_type', this.postType);
        formData.append('is_first_highlighted', this.isFirstHighlighted);
        formData.append('per_page', parseInt(this.settings.per_page));
        formData.append('current_page', this.currentPage);
        formData.append('is_show_date', this.is_show_date);
        formData.append('are_childrens', this.settings.are_childrens);
        formData.append('current_page_id', this.settings.current_page);
        formData.append('is_gray_card', this.settings.is_gray_card);

        if (this.currentCategory)
            formData.append('category', this.currentCategory);

        if (this.settings.exclude)
            formData.append('exclude', this.settings.exclude);

        jQuery.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { maxNumPages, paged, html } = data;

                this.maxPages = maxNumPages;
                this.news = data.data;
                this.wrapper.innerHTML = html;
                this.paginationController.reset(paged, this.maxPages);
            }.bind(this),
            error: function () {
                this.news = [];
            }.bind(this),
            complete: function () {
                this.loader.classList.add('hide');
                this.scrollToItem();
            }.bind(this),
        });
    }

    initPagination() {
        if (!this.pagination) return;
        this.paginationController = new Pagination(this.pagination, this.root);

        this.root.addEventListener('pagination-change', event => {
            const { detail } = event;

            this.currentPage = detail;

            if (this.isFirstHighlighted) {
                const url = new URL(window.location);
                url.searchParams.set('pg', this.currentPage);
                window.history.pushState(null, '', url.toString());
            }

            this.refreshList();

            if (this.currentPage > 1) {
                this.root.classList.add('pagination-init');
            } else {
                this.root.classList.remove('pagination-init');
            }

            const y = this.root.getBoundingClientRect().top + window.pageYOffset + 80;
            window.scrollTo({ top: y, behavior: 'smooth' });
        });

        const { init_page } = this.settings;
        if (init_page) this.currentPage = parseInt(init_page);
    }

    initCategories() {
        if (!this.categories) return;

        this.categories.forEach(category => {
            category.addEventListener('click', event =>
                this.onCategoryClick(event)
            );
        });
    }

    onCategoryClick(event) {
        event.preventDefault();
        const { currentTarget } = event;
        const category = currentTarget.getAttribute('data-press-list-category');
        this.resetCategories();

        if (this.currentCategory === parseInt(category)) {
            this.currentCategory = null;
        } else {
            this.currentCategory = parseInt(category);
            currentTarget.classList.add('active');
        }

        this.refreshList();
    }

    onFilterSwitch(event) {
        const { detail } = event;
        const { value } = detail;

        this.currentPage = 1;
        this.currentCategory = parseInt(value);
        this.refreshList();
    }

    resetCategories() {
        if (!this.categories) return;

        this.categories.forEach(category => {
            category.classList.remove('active');
        });
    }

    refreshList() {
        this.loader.classList.remove('hide');
        this.loadNews();
    }
}
